import React, { FC, useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import Drawer from "@material-ui/core/Drawer";
import produce from "immer";
import { IS_ENTERPRISE_PAGE_ENABLED } from "config";

import {
  ACCOUNT_SETTINGS_ROUTE_CONFIG,
  ADMIN_MENU_LIST_CONFIG,
  ADMIN_MENU_LIST_CONFIG_WITHOUT_SHIPPOP,
  ENTERPRISE_ROUTE_CONFIG,
  OWNER_MENU_LIST_CONFIG,
  OWNER_MENU_LIST_CONFIG_WITHOUT_SHIPPOP,
  PLAN_USAGE_ROUTE_CONFIG,
  QUICK_START_ROUTE_CONFIG,
  RouterConfigType,
} from "routers/routerConfig";
import {
  CHAT,
  INTEGRATION,
  ORDERS,
  PAGE_NAME_INDEX_ON_PATHNAME,
  PLAN,
  PRODUCTS_INVENTORY,
  PROJECT,
  USER_SETTING,
  PLAN_UPGRADE,
  AI_SETTINGS,
} from "constants/Router";
import Grid from "components/Grid";
import Typography from "components/Typography";
import Avatar from "components/Avatar";
import Button, { ButtonWithIcon } from "components/Button";
import { ProjectList, SelectedProject, MenuList } from "components/MobileLayout";
import { SvgIcon } from "components/Icon";
import Tooltip from "components/Tooltip";
import { IcArrowDown, IcArrowBack, IcLogout, IcHelp, IcPlanUpgrade } from "components/SvgIcons";
import SideNavList from "components/SideNav/SideNavList";
import TabWithLink from "components/TabWithLink";
import { TabWrapper, SubSideWrapper } from "components/SubSideNav/styled";
import useSubscribeTotalOrderNumberStates from "domain/Order/hooks/useSubscribeTotalOrderNumberStates";
import useTotalUnseenChatMessagesChanged from "utils/hooks/Chat/useTotalUnseenChatMessagesChanged";
import useCurrentPage from "routers/useCurrentPage";
import { ButtonColors } from "types/Button";

import { SideNavVariant } from "types/SideNav";
import { PermissionType } from "types/User";
import { ProjectIdType } from "types/Project";
import { TabConfig } from "types/Tab";
import { SideNavMenuContext } from "utils/context";
import useGetProject from "utils/hooks/useGetProject";
import useIsDesktop from "utils/hooks/useIsDesktop";
import useGetUserWithPermission from "utils/hooks/useGetUserWithPermission";
import { isRunningOnMobileApp } from "utils/mobileUtil";

import { getProductTabConfig } from "utils/route";
import {
  List,
  SideNavContainer,
  Wrapper,
  NavLink,
  Logout,
  LineSpacer,
  ListMenuWrapper,
  BackButtonMenu,
} from "./SideNav.styled";
import { BotIndicator } from "./BotIndicator";

type SideNavProps = {
  avatar: string;
  displayName: string;
  id: string;
  isOpen: boolean;
  isOwner: boolean;
  isProjectListToggled: boolean;
  onClickMenuList: () => void;
  onClickProjectList: (projectId: string) => void;
  onClose: (isOpen: boolean) => void;
  onLogout: () => void;
  onToggleProjectList: () => void;
  displayUpgradeButton: boolean;
  permissions?: PermissionType[];
  isAllowedToCreateProject?: boolean;
  variant?: SideNavVariant;
};

const SideNav: FC<SideNavProps> = ({
  avatar,
  displayName,
  isOpen,
  isOwner,
  isProjectListToggled,
  onClickMenuList,
  onClickProjectList,
  onClose,
  onLogout,
  onToggleProjectList,
  displayUpgradeButton,
  permissions = [],
  isAllowedToCreateProject,
  variant = SideNavVariant.TEMPORARY,
}) => {
  const { projectId } = useParams<ProjectIdType>();
  const { currentPage, currentSubRoute } = useCurrentPage();
  const { isShowSecondLevel, handleShowSecondLevel } = useContext(SideNavMenuContext);

  const {
    isIntegrationEnabled,
    isDigitalContentEnabled,
    isShippopEnabled,
    isDeeplePayEnabled,
    isOpenApiConfigurationEnabled,
    isChatbotEnabled,
  } = useGetProject(projectId);
  const { isAdminRole } = useGetUserWithPermission(projectId);
  const isDesktop = useIsDesktop();
  const isMobileApp = isRunningOnMobileApp();
  const { t } = useTranslation();

  const selectedProjectId = (permission: PermissionType) => permission.projectId === projectId;
  const permission = permissions.find(selectedProjectId);

  const ownerTabConfigs = isShippopEnabled ? OWNER_MENU_LIST_CONFIG : OWNER_MENU_LIST_CONFIG_WITHOUT_SHIPPOP;
  const adminTabConfigs = isShippopEnabled ? ADMIN_MENU_LIST_CONFIG : ADMIN_MENU_LIST_CONFIG_WITHOUT_SHIPPOP;
  let menuListConfig = isOwner ? ownerTabConfigs : adminTabConfigs;

  const { data: totalOrderNumberStatesData } = useSubscribeTotalOrderNumberStates(projectId);
  const { totalUnseenChatMessages } = useTotalUnseenChatMessagesChanged(projectId);

  const totalOrderNumberStates = totalOrderNumberStatesData?.totalOrderNumberStates;
  const totalUnseenChatMessagesChanged = totalUnseenChatMessages.totalUnseenChatMessages;

  if (totalOrderNumberStates?.ALL != null || totalUnseenChatMessagesChanged) {
    menuListConfig = produce(menuListConfig, (draft) => {
      if (totalOrderNumberStates?.ALL != null) {
        const index = draft.findIndex((menuList) => menuList.page === ORDERS);
        if (index !== -1) draft[index].badgeNo = totalOrderNumberStates.ALL;
      }

      if (totalUnseenChatMessagesChanged) {
        const index = draft.findIndex((menuList) => menuList.page === CHAT);
        if (index !== -1) draft[index].badgeNo = totalUnseenChatMessagesChanged;
      }
    });
  }

  if (!isIntegrationEnabled && !isOpenApiConfigurationEnabled) {
    menuListConfig = menuListConfig.filter(({ page }: RouterConfigType) => page !== INTEGRATION);
  }

  if (isDigitalContentEnabled) {
    const tabConfigHeader = getProductTabConfig(isDigitalContentEnabled);

    menuListConfig = menuListConfig.map((menuConfig) => {
      if (menuConfig.page === PRODUCTS_INVENTORY) {
        return {
          ...menuConfig,
          tabConfig: {
            header: tabConfigHeader,
          },
        };
      }

      return menuConfig;
    });
  }

  const handleClickFirstLevelMenu = () => {
    handleShowSecondLevel(true);
    onClickMenuList();
  };

  const handleClickSecondLevelMenu = () => {
    handleShowSecondLevel(false);
  };

  const planUsageRouteConfig = PLAN_USAGE_ROUTE_CONFIG;

  let selectedMenu = menuListConfig.find(({ page }: RouterConfigType) => page === currentPage);
  if (currentPage === PLAN) {
    selectedMenu = planUsageRouteConfig;
  } else if (currentPage === USER_SETTING) {
    selectedMenu = ACCOUNT_SETTINGS_ROUTE_CONFIG;
  }

  const tabConfig = selectedMenu && (selectedMenu as RouterConfigType).tabConfig;

  const isShowSecondLevelMenu = isShowSecondLevel && isDesktop && Boolean(tabConfig);

  const renderMenuList = () => {
    if (isShowSecondLevelMenu) {
      return (
        <SubSideWrapper>
          <BackButtonMenu onClick={handleClickSecondLevelMenu}>
            <ButtonWithIcon className="rounded-circle">
              <SvgIcon component={IcArrowBack} fontSize="small" className="m-0 pr-1 pb-1" />
            </ButtonWithIcon>
            <Typography variant="body3" color="primary" className="pl-3">
              {t("Back to main menu")}
            </Typography>
          </BackButtonMenu>
          <TabWrapper>
            <TabWithLink
              tabConfig={tabConfig as TabConfig}
              path={currentPage}
              pathIndex={PAGE_NAME_INDEX_ON_PATHNAME}
            />
          </TabWrapper>
        </SubSideWrapper>
      );
    }

    return <MenuList lists={menuListConfig} handleClick={handleClickFirstLevelMenu} />;
  };

  useEffect(() => {
    if (currentSubRoute) {
      handleShowSecondLevel(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSubRoute]);

  return (
    <Wrapper>
      <Drawer variant={variant} open={isOpen} onClose={onClose}>
        <SideNavContainer>
          <Grid container className="h-100">
            <Grid item xs={12}>
              <NavLink to="#" onClick={onToggleProjectList}>
                {permissions && <SelectedProject permission={permission} />}
                <SvgIcon className="ml-2" component={IcArrowDown} fontSize="small" />
              </NavLink>
              <LineSpacer className="m-0" />
              {!isProjectListToggled && (
                <>
                  <SideNavList
                    currentPage=""
                    link={`/${PROJECT}/${projectId}/${AI_SETTINGS}`}
                    onClickMenuList={handleClickFirstLevelMenu}
                    page={AI_SETTINGS}
                    title={AI_SETTINGS}
                    disabled={isAdminRole}
                  >
                    <BotIndicator projectId={projectId} isChatbotEnabled={isChatbotEnabled} isAdmin={isAdminRole} />
                  </SideNavList>

                  <LineSpacer className="m-0" />
                </>
              )}

              <ListMenuWrapper>
                {isProjectListToggled && permissions ? (
                  <ProjectList
                    permissions={permissions}
                    isAllowedToCreateProject={isAllowedToCreateProject}
                    handleClick={onClickProjectList}
                  />
                ) : (
                  renderMenuList()
                )}
              </ListMenuWrapper>
            </Grid>

            {!isShowSecondLevelMenu && (
              <Grid item xs={12} className="align-self-end">
                {isOwner && !isMobileApp && !isDesktop && displayUpgradeButton && (
                  <SideNavList
                    currentPage={currentPage}
                    link={`/${PROJECT}/${projectId}/${PLAN}/${PLAN_UPGRADE}`}
                    onClickMenuList={handleClickFirstLevelMenu}
                    page={PLAN_UPGRADE}
                    title={planUsageRouteConfig.title}
                  >
                    <Button minHeight={40} size="small" fullWidth color={ButtonColors.NEW_PRIMARY}>
                      <Typography variant="body4" color="inherit">
                        <SvgIcon
                          className="ml-1 pb-0 pr-1"
                          component={IcPlanUpgrade}
                          fontSize="small"
                          htmlColor="inherit"
                        />
                        {t("billing.link.upgradePlan")}
                      </Typography>
                    </Button>
                  </SideNavList>
                )}

                <LineSpacer />

                {isOwner && IS_ENTERPRISE_PAGE_ENABLED && Boolean(permission && permission.isEnterprise) && (
                  <SideNavList
                    currentPage={currentPage}
                    Icon={ENTERPRISE_ROUTE_CONFIG.icon}
                    link={`/${PROJECT}/${projectId}${ENTERPRISE_ROUTE_CONFIG.link}`}
                    onClickMenuList={onClickMenuList}
                    page={ENTERPRISE_ROUTE_CONFIG.page}
                    title={ENTERPRISE_ROUTE_CONFIG.title}
                  />
                )}

                {isOwner && !isMobileApp && (
                  <SideNavList
                    currentPage={currentPage}
                    Icon={planUsageRouteConfig.icon}
                    link={`/${PROJECT}/${projectId}${planUsageRouteConfig.link}`}
                    onClickMenuList={handleClickFirstLevelMenu}
                    page={planUsageRouteConfig.page}
                    title={planUsageRouteConfig.title}
                  />
                )}

                {!isDesktop && (
                  <>
                    {isOwner && (
                      <SideNavList
                        currentPage={currentPage}
                        link={`/${PROJECT}/${projectId}${QUICK_START_ROUTE_CONFIG.link}`}
                        onClickMenuList={onClickMenuList}
                        page={QUICK_START_ROUTE_CONFIG.page}
                        title={QUICK_START_ROUTE_CONFIG.title}
                      >
                        <SvgIcon className="mx-3" component={IcHelp} fontSize="large" />
                        <Typography variant="body3" color="primary">
                          {t("Help")}
                        </Typography>
                      </SideNavList>
                    )}

                    <SideNavList
                      currentPage={currentPage}
                      link={`/${PROJECT}/${projectId}${ACCOUNT_SETTINGS_ROUTE_CONFIG.link}`}
                      onClickMenuList={onClickMenuList}
                      page={ACCOUNT_SETTINGS_ROUTE_CONFIG.page}
                      title={ACCOUNT_SETTINGS_ROUTE_CONFIG.title}
                    >
                      <Avatar size="35px" alt="userAvatar" className="mx-2" src={avatar} />
                      <p className="m-0">{displayName}</p>
                    </SideNavList>
                  </>
                )}

                <Logout onClick={onLogout}>
                  <Tooltip
                    disableHoverListener={!isDesktop}
                    title={(isDesktop && t("Log out")) as string}
                    placement="right-start"
                  >
                    <List>
                      <SvgIcon className="mx-3" component={IcLogout} fontSize="large" />
                      {t("Log out")}
                    </List>
                  </Tooltip>
                </Logout>
              </Grid>
            )}
          </Grid>
        </SideNavContainer>
      </Drawer>
    </Wrapper>
  );
};

export default SideNav;
